import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/init";

export const logAnalyticsEvent = (eventName, eventParams = {}) => {
  if (analytics) {
    process.env.NODE_ENV === "development"
      ? console.log(eventName, eventParams)
      : logEvent(analytics, eventName, eventParams);
  }
};

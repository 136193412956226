import { createSlice , createAsyncThunk} from "@reduxjs/toolkit";
import { api } from "../../utils/api";


export const fetchUser = createAsyncThunk("user/fetchUser", async ({userName}) => {
    try{
        const response = await api.post("/user/get-userID-by-userName", { userName });
        if (response.data.userID === ""){
          throw new Error("user does not exists")
        }
        return response.data.userID;
    } catch (error) {
        throw error;
    }
});

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default userSlice.reducer;
import React, { useEffect } from "react";
import { Route, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFeed } from "../redux/slice/feed";
import FeedComponents from "../components/feedComponents";
import Footer from "../components/Footer";

import { logAnalyticsEvent } from "../utils/analytics";
import { track } from "../utils/traking";

function Feed() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.feedSlice);
  // fetch feeds
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(fetchFeed({ feedID: id }, { signal }));

    return () => {
      controller.abort();
    };
  }, [dispatch, id]);

  
  useEffect(() => {
    logAnalyticsEvent("analytics/feed-page-load")
    track("tracking/feed-page-load")
  }, [])

  if (loading)
    return (
      <main className="max-w-[640px] mx-auto flex flex-col h-screen gap-8 items-center pt-8 px-2 md:px-4 py-4">
        <div className="bg-[#ffffff1a] w-full max-w-[640px] grow rounded-3xl p-5">
          <div className="w-full px-4 bg-[#ffffff15] animate-pulse rounded-full p-4">
            <div className="bg-[#ffffff1f] rounded-full size-10" />
          </div>
        </div>
        <Footer parentPage={"feed"}/>
      </main>
    );
  if (error)
    return (
      <main className="flex flex-col h-screen gap-8 items-center pt-8">
        <div className="grow flex items-center text-xl">{error}</div>
        <Footer parentPage={"feed"}/>
      </main>
    );

  return (
    <React.Fragment>
      <main className="grow overflow-auto scroll-none">
        <div className="h-[100%] flex flex-col items-center box-border pt-8">
          {data.map((feed, index) => {
            return <FeedComponents key={index} {...feed} />;
          })}
        </div>
      </main>
      <Footer copyright parentPage={"feed"}/>
    </React.Fragment>
  );
}

export default Feed;

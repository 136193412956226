import { configureStore } from "@reduxjs/toolkit";
import FeedReducer from './slice/feed';
import ProfileReducer from './slice/Profile';
import NGLReducer from "./slice/NGL";
import UserReducer from "./slice/user";

export const store = configureStore({
    reducer : {
        user : UserReducer,
        feedSlice : FeedReducer,
        profileSlice : ProfileReducer,
        NGL : NGLReducer
    }
})
import React from "react";
import { Link } from "react-router-dom";
function Support() {
  return (
    <React.Fragment>
      <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
        <section className=" flex flex-col gap-4 mt-4">
          <img
            src="/images/Meta/logo.png"
            className="size-60 self-center rounded-3xl mt-16 mb-8"
          />
          <div className="flex items-center text-lg gap-4 flex-col">
            <p>Please contact us for any support at</p>
            <p>Email: <Link to="mailto:harshit@popfeedapp.com">harshit@popfeedapp.com</Link></p>
            <p>Phone: <Link to="tel:+16282229332">+16282229332</Link></p>
            <p>Mountain View, California</p>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default Support;

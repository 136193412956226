import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
};

export const NGLSlice = createSlice({
  name: "NGL",
  initialState,
  reducers: {
    setSentFrom: (state, action) => {
      state.username = action.payload;
    },
  },
});

export const { setSentFrom } = NGLSlice.actions;
export default NGLSlice.reducer;

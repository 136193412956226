import { logAnalyticsEvent } from "../utils/analytics";
import { track } from "../utils/traking";

function Footer({copyright, parentPage}) {
  function storeAnalytics(){
    let params;
    switch(parentPage){
      case "home":
        params = {
          from : "pookie parent page"
        }
        break;
      case "profile":
        params = {
          from : "pookie profile page"
        }
        break;
      case "feed":
        params = {
          from : "pookie feed page"
        }
        break;
      default:
        params = {}
        break;
    }
    logAnalyticsEvent("analytics/download_button_click", params)
    track("tracking/download_button_click", params)
  }
  return (
    <div className="p-4 flex flex-col items-center gap-3">
      <a onClick={storeAnalytics} target="_blank" href="https://apps.apple.com/app/pookie-match-with-your-crush/id6692635381" className="mt-8 self-center px-8 py-2 rounded-full flex gap-3 items-center bg-[#272727]">
        <img src={"/svg/apple.svg"} className="h-10"/>
        <div className="flex flex-col">
          <span className="text-sm">Download on the</span>
          <span className="font-semibold text-2xl">App Store</span>
        </div>
      </a>
      {copyright ? 
      <span className="text-gray-300 font-semibold text-base">
        Copyright © 2024, Harshit Foundation
      </span>
      : null}
    </div>
  );
}

export default Footer;

import Footer from "../components/Footer";
import React, { useEffect } from "react";
import { logAnalyticsEvent } from "../utils/analytics";
import { track } from "../utils/traking";
function Home() {
  useEffect(() => {
    logAnalyticsEvent("analytics/parent-page-load")
    track("tracking/parent-page-load")
  }, [])
  return (
    <React.Fragment>
      <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
        <section className=" flex flex-col gap-4 mt-4">
          <img
            src="/images/Meta/logo.png"
            className="size-60 self-center rounded-3xl mt-16 mb-8"
          />
          <p className="font-semibold text-center text-3xl">
            Find your Pookie on
            <br />
            Instagram now.
          </p>
        </section>
        <Footer copyright parentPage={"home"}/>
      </div>
    </React.Fragment>
  );
}

export default Home;
